
import { Options, Vue } from 'vue-class-component';
import { CommunityClient, ForumTagClient, PostClient } from '@/services/Services';
import SearchModal from './modals/searchModal.vue';
import NewForumPostModal from './modals/newForumPostModal.vue';
import TagsModal from './modals/tagsModal.vue';
import PostsListModal from './modals/postsListModal.vue';
import * as CONST from '@/const';
import * as OM from '@/model';
import store from '@/store';
import { Watch } from 'vue-property-decorator';

@Options({})
export default class Forum extends Vue {

    topics: OM.TagPostVM[] = [];
    search: OM.ForumPostSearchVM = new OM.ForumPostSearchVM();
    tags: OM.ForumTag[] = [];
    publishedTag: OM.ForumTag[] = [];
    followedTags: string[] = [];
    communityIdentifier: string = "";

    created() {
        this.init();
    }

    @Watch("$route.params.slug")
    async init() {
        var community = await CommunityClient.getBySlug(this.$route.params.slug.toString())

        this.search.communityIdentifier = community.identifier;
        this.communityIdentifier = community.identifier;

        if(store.state.selectedTagFromSearch != null) {
            this.search.tags.push(store.state.selectedTagFromSearch)
            store.state.selectedTagFromSearch = null;
        }

        this.getTags();
        Promise.all([
            ForumTagClient.getPublishedTagAndPostsNumber(this.search.communityIdentifier),
        ]).then(x => {
            this.topics = x[0];
            console.log(this.topics)
            console.log(this.topics);
            if(this.$route.query.f){
                setTimeout(() => {
                    let post: any = this.$refs[<string>this.$route.query.f];
                    if(post){
                        post.$el.classList.add('evidenzia');

                        post.$el.scrollIntoView();
                        setTimeout(() => {
                            post.$el.classList.remove('evidenzia')
                        }, 2000)
                    }
                }, 200)
            }
        })
    }

    showPosts(topic: OM.TagPostVM) {
        var forumTag = new OM.ForumTag();
        forumTag.identifier = topic.tag.identifier;
        forumTag.tag = topic.tag.tag;
        forumTag.description = topic.tag.description;

        this.$opModal.show(PostsListModal, {
            communityIdentifier: topic.communityIdentifier,
            topic: forumTag
        },() => {
            this.init()
        } )
    }

    getTags() {
        Promise.all([
            ForumTagClient.getEnabledTag(this.search.communityIdentifier),
            ForumTagClient.getPublishedTag(this.search.communityIdentifier),
            ForumTagClient.getFollowedTagsByCommunity(this.search.communityIdentifier)
        ]).then( x => {
            this.tags = x[0];
            this.publishedTag = x[1].map( x => {
                return x.tag
            });
            this.followedTags = x[2];
        })
    }

    newPost() {
        console.log(this.communityIdentifier)
        this.$opModal.show(NewForumPostModal, {
            containerIdentifier: this.communityIdentifier,
            postType: CONST.NotificationType.CommunityPostNew,
            tags: this.publishedTag
        }, () => {
            this.init();
        });
    }

    filter() {
        this.$opModal.show(SearchModal, {
            search : this.search,
            tags: this.tags,
            followedTags: this.followedTags,
            callback: (search: OM.ForumPostSearchVM) => {
                this.search.searchText = search.searchText;
                this.search.tags = search.tags;
                this.$opModal.closeLast();
                this.init();
            }
        });
    }

    editTags() {
        this.$opModal.show(TagsModal, {
            communityIdentifier: this.search.communityIdentifier,
            closeCallback: () => {
                this.$opModal.closeAll();
                this.init();
            }
        }, () => {
            this.getTags();
        });
    }

}

