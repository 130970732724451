
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Toggler extends Vue {

    @Prop() value: boolean;
    
    toggle() {
        this.$emit('input', !this.value);
    }

}
