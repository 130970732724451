
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BaseCommunityClient, CommunityClient } from '@/services/Services';
import ConfirmModal from '@/components/confirmModal.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import * as VM from '@/viewModel';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class EditTagModal extends Vue {

    @Prop({
        default: () => new VM.SaveForumTagVM()
    }) tag: VM.SaveForumTagVM;

    @Prop() callback: () => void;

    local: VM.SaveForumTagVM = new VM.SaveForumTagVM();
    emptyGuid: string = "00000000-0000-0000-0000-000000000000";

    created() {
        this.local = {...this.tag};
    }

    save() {
        BaseCommunityClient.saveTag(this.local)
        .then( x => {
            this.callback();
        }).catch( x => {
            var buttonText = LocalizationServices.getLocalizedValue("app_ok", "Ok");
            this.$opModal.show(ModalSuccessError, { 
                isError: true,
                message: x.message, 
                buttonText: buttonText,
            });
        });
    }

    get disabled() {
        return !this.local.tag.tag || !this.local.tag.description;
    }

    deleteTopic() {
        var text =  LocalizationServices.getLocalizedValue("app_vuoieliminarequestotopic", "Vuoi eliminare questo topic?");
        var text2 =  LocalizationServices.getLocalizedValue("app_tuttiIPostAndrannoPersi", "Tutti i post relativi a questo topic andranno persi");
        var textValue = text + " " + text2;
        this.$opModal.show(ConfirmModal, {
            text: textValue,
            okCb: () => {
                BaseCommunityClient.disableTag(this.tag.tag.identifier, this.tag.communityIdentifier)
                .then( x => {
                    this.callback();
                    this.$opModal.closeLast();
                })
            }
        })
    }

}
