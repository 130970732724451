
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PostClient } from '@/services/Services';
import Post from '@/components/post.vue';
import * as OM from '@/model';

@Options({
    components: {
        Post
    }
})
export default class PostsListModal extends Vue {

    @Prop({
        default: ""
    }) communityIdentifier: string;

    @Prop({
        default: () => new OM.ForumTag()
    }) topic: OM.ForumTag;

    posts: OM.PostVm[] = [];
    search: OM.ForumPostSearchVM = new OM.ForumPostSearchVM();

    created() {
        this.search.communityIdentifier = this.communityIdentifier;
        this.search.tags.push(this.topic);
        this.init();
    }

    onRemoved(event , i){
        this.init()
    }

    init() {
        Promise.all([
            PostClient.getForumPostByParent(this.search),
        ]).then(x => {
            this.posts = x[0];
            if(this.$route.query.f){
                setTimeout(() => {
                    let post: any = this.$refs[<string>this.$route.query.f];
                    if(post){
                        post.$el.classList.add('evidenzia');

                        post.$el.scrollIntoView();
                        setTimeout(() => {
                            post.$el.classList.remove('evidenzia')
                        }, 2000)
                    }
                }, 200)
            }
        })
    }

}

