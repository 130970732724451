
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ForumTagClient } from '@/services/Services';
import Toggler from '@/components/toggler.vue';
import * as OM from '@/model';

@Options({
    components: {
        Toggler
    }
})
export default class SearchModal extends Vue {

    @Prop({
        default: () => new OM.ForumPostSearchVM()
    }) search: OM.ForumPostSearchVM;

    @Prop() callback: (value: OM.ForumPostSearchVM) => void;

    @Prop({
        default: () => []
    }) tags: OM.ForumTag[];

    @Prop({
        default: () => []
    }) followedTags: string[];

    localSearch: OM.ForumPostSearchVM = new OM.ForumPostSearchVM();

    created(){
        this.localSearch = this.search;
    }

    next(){
        this.callback(this.localSearch);
    }

    editTags(tag: OM.ForumTag){
        var obj = this.localSearch.tags.find( x => {
            return x.identifier == tag.identifier
        })

        if(!obj){
            this.localSearch.tags.push(tag);
        }
        else {
            var index = this.localSearch.tags.indexOf(obj);
            this.localSearch.tags.splice(index, 1);
        }
    }

    getTagClass(tag: OM.ForumTag){
        var obj = this.localSearch.tags.find( x => {
            return x.identifier == tag.identifier
        })

        if(!obj)
            return "btn-blue-hollow";
        else
            return "btn-blue";
    }

    setSubscription(value: boolean, tagIdentifier: string){
        if(value) {
            ForumTagClient.followTag(tagIdentifier)
            .then( x => {
                this.followedTags.push(tagIdentifier);
            })
        } else {
            ForumTagClient.unfollowTag(tagIdentifier)
            .then( x => {
                var index = this.followedTags.indexOf(tagIdentifier);
                this.followedTags.splice(index, 1);
            })
        }
    }

}
