
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BaseCommunityClient, ForumTagClient } from '@/services/Services';
import EditTagModal from './editTagModal.vue';
import * as VM from '@/viewModel';

@Options({})
export default class TagsModal extends Vue {

    @Prop({
        default: ""
    }) communityIdentifier: string;

    @Prop() closeCallback: any;

    tagsPublished: VM.SaveForumTagVM[] = [];
    tagsNotPublished: VM.SaveForumTagVM[] = [];

    created() {
        this.init();
    }

    init() {
        Promise.all([
            BaseCommunityClient.getEnabledAndNotPublishedTags(this.communityIdentifier),
            ForumTagClient.getPublishedTag(this.communityIdentifier),
        ]).then( x => {
            this.tagsNotPublished = x[0];
            this.tagsPublished = x[1];
        })
    }

    editTopic(tag: VM.SaveForumTagVM) {
        if(!tag){
            tag = new VM.SaveForumTagVM();
            tag.communityIdentifier = this.communityIdentifier;
        }

        this.$opModal.show(EditTagModal, {
            tag: tag,
            callback : () => {
                this.init();
                this.$opModal.closeLast();
            }
        })
    }

}

